@import url('https://fonts.googleapis.com/css?family=Lora:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,500,600,600i,700,700i&display=swap');

.blogleft {
    float: left;
    width: 70%;
    margin-right: 5%;
    padding: 25px;
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    
    background: #fff;
    position: relative;
}
.blogright {
    width: 25%;
    float: left;
}
.categories .blogleft, .latest .blogleft {
    margin-top: -60px;
}

.post-img img{
    width: 100%;
}
.wp-block-image img {
    width: 100%;
}
.blogleft .content p {
    font-size: 18px;
    margin-top: 15px;
}
.blogleft .content ul li {
    font-size: 22px;
}
.blogleft h1{
    font-size: 36px;
    font-family: 'Montserrat', sans-serif;
    line-height: 48px;
    margin-bottom: 20px;
    text-transform: capitalize;
    font-weight: 600;
}
.auth {
    font-size: 16px;
    color: #47ccde;
    font-weight: bold;
    margin-left: 5px;
    margin-bottom: 20px;
}
.content span{
    font-size: 16px;
    margin-bottom: 20px;
}
.sbimg img{
    width: 100%;
}
.sbpost{
    box-shadow: 0 2px 48px 0 rgba(0,0,0,.08);
    padding: 9px;
    margin: 10px;

}
.sbtitle a {
    font-weight: 100;
    text-decoration: none;
}
.blogright h3 {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    color: #47ccde;
}
span.authimg img {
    width: 50px;
    border-radius: 50px;
}
.blogright .sbbanner{
    margin-top: 50px;
}
.sbbanner img{
width: 100%;
    
}
.post .content img{
    text-align: center;
    display: block;
    margin: 0 auto;
}
.desksocial {
    display: flex;
    justify-content: center;
}
.desksocial div {
    outline: 0;
}