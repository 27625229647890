
@import url('https://fonts.googleapis.com/css?family=Work+Sans&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap');

body{
    font-family: 'Lato', sans-serif;
}
.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999999999;
    background: white;
    width: 100vw;
    height: 100vh;
    text-align: center;
    line-height: 100vh;
}


.homepage {
    margin-top: -20px;
}
.grid-box {
    display: flex;
    width: 100%;
}
.grid-right {
    display: flex;
    width: 100%;
    overflow: hidden;
}
.grid-down,
.grid-up {
    display: flex;
    width: 100%;
    flex-direction: column;
    overflow: hidden;
}
.grid-left {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    position: relative;
}
.grid-up div, .grid-down div{
    position: relative;
}
.grid-left h2{
    position: absolute;
    color: #ffffffed;
    font-size: 20px;
    padding: 15px;
    top: 70%;
    font-family: jost;
    letter-spacing: -0.025em;
    margin: unset;
    z-index: 999;
}
p.cate {
    position: absolute;
    top: 50%;
    padding: 2px 10px;
    color: #fff;
    background: #47ccde;
    font-size: 9px;
    margin-left: 15px;
    border-radius: 2px;
}
p.cate:hover, a.cate:hover{
    background: #0b8d5d;
}
a.cate {
    position: absolute;
    top: 65%;
    padding: 2px 10px;
    color: #fff;
    background: #47ccde;
    font-size: 12px;
    margin-left: 15px;
    border-radius: 2px;
    text-decoration: none;
}
.grid-right h2{
    position: absolute;
    color: #ffffffed;
    top: 55%;
    font-size: 14px;
    padding: 15px;
    font-family: jost;
    letter-spacing: -0.025em;
    margin: unset;
    z-index: 999;
}
.categoryhome .bgc1 {
    background-size: cover;
    height: 300px;
    filter: brightness(50%);
    transition: transform 2s, filter 1.5s ease-in-out;
    transform-origin: center center;
}
.categoryhome .bgc1:hover{
    filter: brightness(100%);
}
.categoryhome .bgc2:hover, .categoryhome .bgc3:hover, .categoryhome .bgc4:hover, .categoryhome .bgc5:hover{
    filter: brightness(100%);
}


.categoryhome .bgc2 {
    background-size: cover;
    height: 150px;
    width: 100%;
    filter: brightness(50%);
    transition: transform 2s, filter 1.5s ease-in-out;
    transform-origin: center center;
}
.categoryhome .bgc3 {
    background-size: cover;
    height: 150px;
    width: 100%;
    filter: brightness(50%);
    transition: transform 2s, filter 1.5s ease-in-out;
    transform-origin: center center;
}
.categoryhome .bgc4 {
    
    background-size: cover;
    height: 150px;
    width: 100%;
    filter: brightness(50%);
    transition: transform 2s, filter 1.5s ease-in-out;
    transform-origin: center center;
}
.categoryhome .bgc5 {
    background-size: cover;
    height: 150px;
    width: 100%;
    filter: brightness(50%);
    transition: transform 2s, filter 1.5s ease-in-out;
    transform-origin: center center;
}
.bgcp {
    margin: unset;
    padding: unset;
}
.latest-news h3 {
    color: #00a950;
    
}
.homepage .post-image{
    overflow: hidden;
    height: 160px;
}
.homepage .post-image1{
    overflow: hidden;
    position: relative;
}
.homepage .post-image img, .homepage .post-image1 img {
    width: 100%;
    filter: brightness(70%);
    transition: transform 2s, filter 1.5s ease-in-out;
    height:100%
}
.homepage .post-image img:hover, .homepage .post-image1 img:hover{
    transform: scale(1.5);
    filter: brightness(100%)
}

.homepage .ln-box1 {
   
    box-shadow: 0 2px 48px 0 rgba(0,0,0,.08);
    padding: 20px;
    overflow: hidden;
}
.homepage .ln-box2{
    overflow: hidden;
    height: 430px;
    box-shadow: 0 2px 48px 0 rgba(0,0,0,.08);
    padding: 9px;
    margin: 10px;
}

.homepage .ln-box2 .post-image {
    height: 160px;
    overflow: hidden;
}
.ln-content {
    margin-bottom: 15px;
}
.ln-content p{
  
    font-size: 18px;
    color: #222;
    font-weight: 400;
}
.post-image{
    position: relative;
}
.ln-box1 a.category, .ln-box2 .post-image a{
    position: absolute;
    z-index: 9;
    background: #47ccde;
    bottom: 0;
    left: 0;
    text-decoration: none;
    color: #fff;
    text-align: left;
    font-size: 12px;
}
.ln-box1 a.category {
    padding: 1px 10px;
}
.ln-content .author, .ln-content .date{
    color: #b7b9c2;
    text-decoration: none;
    text-transform: uppercase;
    font-family: jost;
    font-size: 0.8125rem;
    font-weight: 500;
    letter-spacing: 0.125em;
    margin-right: 25px;
}
.ln-content a.category:hover{
    color: #000;
}
.ln-box1 .ln-content .title a{
    font-size: 16px;
}
.ln-content .title{
    font-size: 1.5rem;
    text-decoration: none; 
    line-height: 25px;
    margin-top: 10px;
    margin-bottom: 0px;
    height: 50px;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: 2s;
}

.ln-content .title a{
    text-decoration: none; 
    color: #000;
    font-family: jost;
    letter-spacing: -0.025em;
    font-size: 16px;
}
.ln-content .title a:hover{
    color: #6c757d
}
.ln-content .excerpt{
    font-size: 15px;
    line-height: 1.5;
    margin-top: 1rem;
    font-family: 'Lato', sans-serif;
    text-align: justify;
    height: 90px;
    overflow: hidden;
}
.ln-content .readmore, .entry-details a{
    background: #f2f4f6;
    color: #000;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    padding: 7px;
    border-radius: 70px;
    text-decoration: none;
    transition: cubic-bezier(0.6, 0.04, 0.98, 0.335);
    text-transform: uppercase;
    font-size: 11px;
    font-family: jost;
}
.ln-content .readmore:hover, .entry-details a:hover{
    color: #f2f4f6;
    background: #000;
}
.ln-content .readmore:after,  .entry-details a:after{
    display: inline-block;
    margin-left: 8px;
    font-family: FontAwesome;
    font-size: 8px;
    content: "\f054";
    margin-top: 2px;
}

