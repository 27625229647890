.header .navbar-default{
    background: transparent;
    border-radius: unset;
    border: none
}
.header .navbar-default .navbar-nav > .active > a{
    background-color: #47ccde;
    color: #fff;
    
}
.header .navbar-default .navbar-nav > .active > a:hover, .header .navbar-default .navbar-nav > .active > a:focus{
    background-color: #47ccde;
    border: none;
    outline: none;
    color: #fff
}
.dlogo img {
    width: 100%;
    height: 100%;
}
.dlogo {
    width: 62px;
}
.navbar-header .navbar-brand{
    padding: unset;
}