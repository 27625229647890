.footer{
    padding: 30px;
    background: #47ccde;
    margin-top: auto;
    text-align: center;
}
.footer ul {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0;
}
.footer ul li{
    padding: 10px;
}
.footer ul li a{
    color: #fff;
    text-decoration: none;
}