@media (max-width: 767px) {
    .headerbg {
        height: 200px;
        margin-top: unset;
    }
    .blogright{
        display: none;
    }
    .blogleft{
        width: 90%;
    }
    .writerbox {
        height: auto !important;
        margin-bottom: 25px;
    }
    .categories .ln-box2{
        margin: 10px 0;
    }
}