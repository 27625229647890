.oneline{
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
}

.oneline input[type="text"], .oneline input[type="email"], .oneline input[type="tel"] {
    width: 75%;
    height: 30px;
    border: none;
    border: 1px solid #47ccde;
    outline: none;
}
.oneline p {
    margin: unset;
    padding: 5px;
    font-size: 12px;
    font-weight: bold;
}
.cntform{
    width: 80%;
    box-shadow: 0 2px 48px 0 rgba(0,0,0,.08);
    padding: 20px;
    margin: 0 auto;
}
.cntform button{
    border: none;
    background: #47ccde;
    color: #fff;
    padding: 5px 20px;
    margin: 0 auto;
    display: block;
}
.message  textarea{
    width: 88%;
    height: 100px;
    border: none;
    border: 1px solid #47ccde;
    outline: none;
}
