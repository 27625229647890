@import url('https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,500,500i,600&display=swap');

.mfooter {
    background: #47ccde;
    height: 40px;
    width: 100%;
    position: fixed;
    bottom: 0;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    z-index: 9999999;
    color: #fff
}
.mfooter a{
    color: #fff;
    text-decoration: none
}
.ln{
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
    align-items: flex-start;
    position: relative;
    box-shadow: 0 2px 48px 0 rgba(0,0,0,.08);
    margin-bottom: 15px;
}
.ln2{
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
    flex-direction: row-reverse;
    align-items: flex-start;
    position: relative;
    box-shadow: 0 2px 48px 0 rgba(0,0,0,.08);
    margin-bottom: 15px;
}
.lnimage {
    width: 100%;
    height: auto;
    flex: 2;
    position: relative;

}
.ln2 .lnimage img{
    float: right;
}
.lnimage img{
    width: 90%;
    height: 120px;
    object-fit: cover;
    filter: brightness(0.8);
}
.ln-right{
    flex: 2;
}
.lnimage a.category {
    position: absolute;
    left: 5px;
    color: #fff;
    font-size: 8px;
    bottom: 0;
}
.ln-right h2.title {
    font-size: 14px;
    margin: 0;
}
.ln-right h2.title a{
   
    text-decoration: none;
    color: #000;
    font-family: jost;
    letter-spacing: -0.025em;
}
.ln .ln-right h2.title, .ln .ed, .ln .date {
    text-align: end
}
.ln2 .ln-right h2.title {
    text-align: start
    
}
.ln2 .ln-right h2.title a {
    text-decoration: none;
    color: #000;
    font-family: jost;
    letter-spacing: -0.025em;
    
}
.ln2 .lnimage a.category{
    right: 5px;
    left: unset;
}
p.date {
    font-size: 8px;
    margin-bottom: 5px;
}
.ed a {
    text-decoration: none;
    font-size: 6px;
    background: #47ccde;
    padding: 5px 15px;
    color: #fff;
}
.mobile .ln .ed{
    position: absolute;
   right: 10px;
    bottom: 10px;
    
}
.mobile .ln2 .ed{
    position: absolute;
    left: 10px;
    bottom: 10px;
    
}
.mobile h3{
    text-transform: uppercase;
}
.mobile hr {
    border: 0;
    border-top: 2px solid #7d7d7d;
}

.navbar-default .navbar-toggle, .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    border: unset;
    background: transparent;
}
.navbar-default .navbar-toggle .icon-bar {
    background-color: #000;
}
.mobile .navbar-toggle{
    float: left;
}
.mobile .navbar-default .navbar-brand{
    display: none;
}
.mobile h3{
    margin: unset;
}

.sidebar{
    background: #47ccde;
    width: 50%;
    z-index: 9999 !important;
}
.mobile .header {
    height: 50px;
}
.hamburger
  {
    margin-top: 15px;
    margin-left: 18px;
    width: fit-content;
}
.hamburger span
{  background: #47ccde;
  border-radius: 3px;
  display: block;
  margin-bottom: 3px;
  transition: 0.3s all ease;
  height: 2px;
  width: 25px
}
.hamburger span:nth-child(1)
  {width: 20px;
display: none;
}
.hamburger span:nth-child(2)
  {width: 15px}
.hamburger:hover span:nth-child(3)
{  width: 20px}
.hamburger:hover span:nth-child(2)
{  width: 25px}
.hamburger:hover span:nth-child(1)
{  width: 215px}

.mpost .post-img {
    position: absolute;
    top: 0;
    z-index: -1;
}
.mpost .content {
    background: white;
    padding: 10px 20px 10px 25px;
    text-align: justify;
    margin-top: 35%;
    margin-left: 8%;
    margin-bottom: 40px;
}
.mheader {
    height: 40px;
}
.mpost .content h1 {
    font-size: 18px;
    text-align: left;
    text-transform: uppercase;
    font-weight: bold;
}
.mpost .content h1:before {
    content: "";
    position: absolute;
    background: red;
    height: 45px;
    width: 3px;
    left: 12%;
}
.mobile .pagination > li > a {
    font-size: 10px;
    padding: 1px 5px;
}
/* .social{
    display: flex;
    justify-content: center;
    padding: 5px;
    margin-bottom: 50px;
} */
.social {

   
    position: fixed;
    top:50%;
    left: 0;
}
.social div{
    margin-left: 5px;
    outline: none;
}
.mpost .post-img img{
    height: 200px;
}
.mobile .pagination {
    margin-bottom: 50px;
}
.dockicon {
    display: flex;
    justify-content: space-around;
    vertical-align: middle;
    align-items: center;
    align-content: center;
    height: 40px;
}


.mlogo {
    width: 100px;
    margin: 0 auto;
    margin-top: 12px;
}
.mlogo img{
    width: 100%;
}

.mmenu{
    list-style: none;
    color: #fff;
    padding: 15px;
}
.mmenu a{
    color: #fff;
    text-decoration: none
}
.mmenu li {
    padding: 8px;
}
.mmenu .item236:before{
    font-family: "Font Awesome 5 Free";
   content: "\f015";
   display: inline-block;
   padding-right: 5px;
   vertical-align: middle;
   font-weight: 900;
   margin-top: -2px;
}
.mmenu .item237:before{
    font-family: "Font Awesome 5 Free";
   content: "\f001";
   display: inline-block;
   padding-right: 5px;
   vertical-align: middle;
   font-weight: 900;
   margin-top: -2px;
}
.mmenu .item238:before{
    font-family: "Font Awesome 5 Free";
   content: "\f008";
   display: inline-block;
   padding-right: 5px;
   vertical-align: middle;
   font-weight: 900;
   margin-top: -2px;
}
.mmenu .item239:before{
    font-family: "Font Awesome 5 Free";
   content: "\f2e7";
   display: inline-block;
   padding-right: 5px;
   vertical-align: middle;
   font-weight: 900;
   margin-top: -2px;
}
.mmenu .item240:before{
    font-family: "Font Awesome 5 Free";
   content: "\f182";
   display: inline-block;
   padding-right: 5px;
   vertical-align: middle;
   font-weight: 900;
   margin-top: -2px;
}
.mmenu .item241:before{
    font-family: "Font Awesome 5 Free";
   content: "\f5bd";
   display: inline-block;
   padding-right: 5px;
   vertical-align: middle;
   font-weight: 900;
   margin-top: -2px;
}
.mmenu .item243:before{
    font-family: "Font Awesome 5 Free";
   content: "\f03d";
   display: inline-block;
   padding-right: 5px;
   vertical-align: middle;
   font-weight: 900;
   margin-top: -2px;
}
.mmenu .item267:before{
    font-family: "Font Awesome 5 Free";
   content: "\f304";
   display: inline-block;
   padding-right: 5px;
   vertical-align: middle;
   font-weight: 900;
   margin-top: -2px;
}
.mmenu .item287:before{
    font-family: "Font Awesome 5 Free";
   content: "\f6e2";
   display: inline-block;
   padding-right: 5px;
   vertical-align: middle;
   font-weight: 900;
   margin-top: -2px;
}
.mmenu .item288:before{
    font-family: "Font Awesome 5 Free";
   content: "\f2bb";
   display: inline-block;
   padding-right: 5px;
   vertical-align: middle;
   font-weight: 900;
   margin-top: -2px;
}
.mmenu .item301:before{
    font-family: "Font Awesome 5 Free";
   content: "\f557";
   display: inline-block;
   padding-right: 5px;
   vertical-align: middle;
   font-weight: 900;
   margin-top: -2px;
}
.mauthimg img{
    width: 30px;
    border-radius: 50px;
}
.mpcnt img{
    width: 100%;
    object-fit: contain;
    object-position: center;
    padding: 0;
    margin: 5px 0;
    height: auto;
}