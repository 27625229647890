
.video div{
    box-shadow: 0 2px 48px 0 rgba(0,0,0,.08);
    padding: 5px;
    margin: 5px;
}
.videos {
    margin-bottom: 50px;
}
p.vtime {
    font-size: 12px;
}
