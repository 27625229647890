.svgel{
    width: 60%;
    text-align: center;
    display: block;
    margin: 0 auto;
    overflow: hidden;
    padding: 40px;

}
.pathh{
    margin-bottom: 20px;
}
.goback{
    margin-top: 10px;
    background: #03a9f4;
    color: #fff;
    text-decoration: none;
    padding: 8px 25px;
    border-radius: 10px;
}
.path{
    color: #03a9f4;
    opacity: 0.5;
}

.st0{fill:#4A515B;}
.st1{font-family:'Montserrat-Bold';}
.st2{
    font-size:136.0123px;
    margin-left: 20px
}
.st6{font-size:136.0123px;}
.st3{letter-spacing:-3;}
.st4{fill:#03a9f4;}
.st5{fill:#03a9f4;}