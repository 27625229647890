@import url('https://fonts.googleapis.com/css?family=Dancing+Script&display=swap');
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.8.2/css/all.min.css);

.latest .post-image1 img, .latest .post-image img{
    width: 100%;
}
.latest .post-image1{
    position: relative;
}
.latest .post-image{
    overflow: hidden;
    height: 200px;
}
.latest .post-image1{
    overflow: hidden;
    position: relative;
}
.latest .post-image img, .latest .post-image1 img {
    width: 100%;
    filter: brightness(70%);
    transition: transform 2s, filter 1.5s ease-in-out;
    height:100%
}
.latest .post-image img:hover, .latest .post-image1 img:hover{
    transform: scale(1.5);
    filter: brightness(100%)
}

.latest .ln-box1 {
    box-shadow: 0 2px 48px 0 rgba(0,0,0,.08);
    padding: 20px;
  
    overflow: hidden;
}
.latest .ln-box2{
    overflow: hidden;
    height: 450px;
    box-shadow: 0 2px 48px 0 rgba(0,0,0,.08);
    padding: 9px;
    margin: 10px;
}

.latest .ln-box2 .post-image {
    height: 200px;
    overflow: hidden;
}
.headerbg{
    height: 450px;
    margin-top: -20px;
    background-attachment: fixed;
    position: relative;
    width: 100%;
    overflow: hidden;
    background: #47ccde;
}
.headerbg video{
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    z-index: 0;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);

}
.headerbg h2{
    position: absolute;
    left: 50%;
    font-size: 40px;
    color: #fff;
    z-index: 999;
    top: 50%;
    transform: translate(-50%, -50%);
    text-transform: capitalize;
}
.pagination {
    margin: 15px auto;
    display: table;
}
.pagination > .active > a{
    background-color: #47ccde !important;
    border-color: #47ccde !important;
}
.pagination > li > a{
    border: 1px solid #47ccde !important;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: #47ccde !important;
    border-color: #47ccde !important;
    outline: none !important
}
.pagination > li > a, .pagination > li > span{
    color: #47ccde
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: unset !important
}
.latest .ln-content .excerpt{
    height: 70px;
}
.searchbar {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    position: relative;
    margin-top: 20px;
}
.searchbar form.searchform{
    width: 100%;
}
.searchbar input {
    width: 100%;
    border: 4px double black;
}
.searchbar input::placeholder{
    margin-left: 20px
}
.searchbar .sricon {
    position: absolute;
    font-size: 16px;
    top: 15%;
    right: 8px;
}