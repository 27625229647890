.categories .post-image1 img, .categories .post-image img{
    width: 100%;
}
.categories .post-image1{
    position: relative;
}
.categories .post-image{
    overflow: hidden;
    height: 200px;
}
.categories .post-image1{
    overflow: hidden;
    position: relative;
}
.categories .post-image img, .categories .post-image1 img {
    width: 100%;
    filter: brightness(70%);
    transition: transform 2s, filter 1.5s ease-in-out;
    height:100%
}
.categories .post-image img:hover, .categories .post-image1 img:hover{
    transform: scale(1.5);
    filter: brightness(100%)
}

.categories .ln-box1 {
   
    box-shadow: 0 2px 48px 0 rgba(0,0,0,.08);
    padding: 20px;
    overflow: hidden;
}
.categories .ln-box2{
    overflow: hidden;
    height: 450px;
    box-shadow: 0 2px 48px 0 rgba(0,0,0,.08);
    padding: 9px;
    margin: 10px;
}

.categories .ln-box2 .post-image {
    height: 200px;
    overflow: hidden;
}
.categories .ln-content .excerpt{
    height: 70px;
}