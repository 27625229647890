.writerimg{
    width: 130px;
    height: 110px;
    overflow: hidden;
}
.writerimg img{
    width: 100%;
}
.writerbox{
    box-shadow: 0 2px 48px 0 rgba(0,0,0,.08);
    padding: 20px;
    display: flex;
    justify-content: space-between;
    height: 300px;
    margin: 20px;
}
.wcontent {
    width: 70%;
    margin-left: 20px;
    text-align: justify;
}
.wcontent h3{
    margin-top: unset;
}
.wsocial {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    color: #47ccde;
}
.wsocial a{
    color: #47ccde;
}