@import url(https://fonts.googleapis.com/css?family=Work+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Lora:400,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,500,600,600i,700,700i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Dancing+Script&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.8.2/css/all.min.css);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway:300,300i,400,400i,500,500i,600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header .navbar-default{
    background: transparent;
    border-radius: unset;
    border: none
}
.header .navbar-default .navbar-nav > .active > a{
    background-color: #47ccde;
    color: #fff;
    
}
.header .navbar-default .navbar-nav > .active > a:hover, .header .navbar-default .navbar-nav > .active > a:focus{
    background-color: #47ccde;
    border: none;
    outline: none;
    color: #fff
}
.dlogo img {
    width: 100%;
    height: 100%;
}
.dlogo {
    width: 62px;
}
.navbar-header .navbar-brand{
    padding: unset;
}

body{
    font-family: 'Lato', sans-serif;
}
.loader {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 9999999999;
    background: white;
    width: 100vw;
    height: 100vh;
    text-align: center;
    line-height: 100vh;
}


.homepage {
    margin-top: -20px;
}
.grid-box {
    display: flex;
    width: 100%;
}
.grid-right {
    display: flex;
    width: 100%;
    overflow: hidden;
}
.grid-down,
.grid-up {
    display: flex;
    width: 100%;
    flex-direction: column;
    overflow: hidden;
}
.grid-left {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    position: relative;
}
.grid-up div, .grid-down div{
    position: relative;
}
.grid-left h2{
    position: absolute;
    color: #ffffffed;
    font-size: 20px;
    padding: 15px;
    top: 70%;
    font-family: jost;
    letter-spacing: -0.025em;
    margin: unset;
    z-index: 999;
}
p.cate {
    position: absolute;
    top: 50%;
    padding: 2px 10px;
    color: #fff;
    background: #47ccde;
    font-size: 9px;
    margin-left: 15px;
    border-radius: 2px;
}
p.cate:hover, a.cate:hover{
    background: #0b8d5d;
}
a.cate {
    position: absolute;
    top: 65%;
    padding: 2px 10px;
    color: #fff;
    background: #47ccde;
    font-size: 12px;
    margin-left: 15px;
    border-radius: 2px;
    text-decoration: none;
}
.grid-right h2{
    position: absolute;
    color: #ffffffed;
    top: 55%;
    font-size: 14px;
    padding: 15px;
    font-family: jost;
    letter-spacing: -0.025em;
    margin: unset;
    z-index: 999;
}
.categoryhome .bgc1 {
    background-size: cover;
    height: 300px;
    -webkit-filter: brightness(50%);
            filter: brightness(50%);
    -webkit-transition: -webkit-transform 2s, -webkit-filter 1.5s ease-in-out;
    transition: -webkit-transform 2s, -webkit-filter 1.5s ease-in-out;
    transition: transform 2s, filter 1.5s ease-in-out;
    transition: transform 2s, filter 1.5s ease-in-out, -webkit-transform 2s, -webkit-filter 1.5s ease-in-out;
    -webkit-transform-origin: center center;
            transform-origin: center center;
}
.categoryhome .bgc1:hover{
    -webkit-filter: brightness(100%);
            filter: brightness(100%);
}
.categoryhome .bgc2:hover, .categoryhome .bgc3:hover, .categoryhome .bgc4:hover, .categoryhome .bgc5:hover{
    -webkit-filter: brightness(100%);
            filter: brightness(100%);
}


.categoryhome .bgc2 {
    background-size: cover;
    height: 150px;
    width: 100%;
    -webkit-filter: brightness(50%);
            filter: brightness(50%);
    -webkit-transition: -webkit-transform 2s, -webkit-filter 1.5s ease-in-out;
    transition: -webkit-transform 2s, -webkit-filter 1.5s ease-in-out;
    transition: transform 2s, filter 1.5s ease-in-out;
    transition: transform 2s, filter 1.5s ease-in-out, -webkit-transform 2s, -webkit-filter 1.5s ease-in-out;
    -webkit-transform-origin: center center;
            transform-origin: center center;
}
.categoryhome .bgc3 {
    background-size: cover;
    height: 150px;
    width: 100%;
    -webkit-filter: brightness(50%);
            filter: brightness(50%);
    -webkit-transition: -webkit-transform 2s, -webkit-filter 1.5s ease-in-out;
    transition: -webkit-transform 2s, -webkit-filter 1.5s ease-in-out;
    transition: transform 2s, filter 1.5s ease-in-out;
    transition: transform 2s, filter 1.5s ease-in-out, -webkit-transform 2s, -webkit-filter 1.5s ease-in-out;
    -webkit-transform-origin: center center;
            transform-origin: center center;
}
.categoryhome .bgc4 {
    
    background-size: cover;
    height: 150px;
    width: 100%;
    -webkit-filter: brightness(50%);
            filter: brightness(50%);
    -webkit-transition: -webkit-transform 2s, -webkit-filter 1.5s ease-in-out;
    transition: -webkit-transform 2s, -webkit-filter 1.5s ease-in-out;
    transition: transform 2s, filter 1.5s ease-in-out;
    transition: transform 2s, filter 1.5s ease-in-out, -webkit-transform 2s, -webkit-filter 1.5s ease-in-out;
    -webkit-transform-origin: center center;
            transform-origin: center center;
}
.categoryhome .bgc5 {
    background-size: cover;
    height: 150px;
    width: 100%;
    -webkit-filter: brightness(50%);
            filter: brightness(50%);
    -webkit-transition: -webkit-transform 2s, -webkit-filter 1.5s ease-in-out;
    transition: -webkit-transform 2s, -webkit-filter 1.5s ease-in-out;
    transition: transform 2s, filter 1.5s ease-in-out;
    transition: transform 2s, filter 1.5s ease-in-out, -webkit-transform 2s, -webkit-filter 1.5s ease-in-out;
    -webkit-transform-origin: center center;
            transform-origin: center center;
}
.bgcp {
    margin: unset;
    padding: unset;
}
.latest-news h3 {
    color: #00a950;
    
}
.homepage .post-image{
    overflow: hidden;
    height: 160px;
}
.homepage .post-image1{
    overflow: hidden;
    position: relative;
}
.homepage .post-image img, .homepage .post-image1 img {
    width: 100%;
    -webkit-filter: brightness(70%);
            filter: brightness(70%);
    -webkit-transition: -webkit-transform 2s, -webkit-filter 1.5s ease-in-out;
    transition: -webkit-transform 2s, -webkit-filter 1.5s ease-in-out;
    transition: transform 2s, filter 1.5s ease-in-out;
    transition: transform 2s, filter 1.5s ease-in-out, -webkit-transform 2s, -webkit-filter 1.5s ease-in-out;
    height:100%
}
.homepage .post-image img:hover, .homepage .post-image1 img:hover{
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    -webkit-filter: brightness(100%);
            filter: brightness(100%)
}

.homepage .ln-box1 {
   
    box-shadow: 0 2px 48px 0 rgba(0,0,0,.08);
    padding: 20px;
    overflow: hidden;
}
.homepage .ln-box2{
    overflow: hidden;
    height: 430px;
    box-shadow: 0 2px 48px 0 rgba(0,0,0,.08);
    padding: 9px;
    margin: 10px;
}

.homepage .ln-box2 .post-image {
    height: 160px;
    overflow: hidden;
}
.ln-content {
    margin-bottom: 15px;
}
.ln-content p{
  
    font-size: 18px;
    color: #222;
    font-weight: 400;
}
.post-image{
    position: relative;
}
.ln-box1 a.category, .ln-box2 .post-image a{
    position: absolute;
    z-index: 9;
    background: #47ccde;
    bottom: 0;
    left: 0;
    text-decoration: none;
    color: #fff;
    text-align: left;
    font-size: 12px;
}
.ln-box1 a.category {
    padding: 1px 10px;
}
.ln-content .author, .ln-content .date{
    color: #b7b9c2;
    text-decoration: none;
    text-transform: uppercase;
    font-family: jost;
    font-size: 0.8125rem;
    font-weight: 500;
    letter-spacing: 0.125em;
    margin-right: 25px;
}
.ln-content a.category:hover{
    color: #000;
}
.ln-box1 .ln-content .title a{
    font-size: 16px;
}
.ln-content .title{
    font-size: 1.5rem;
    text-decoration: none; 
    line-height: 25px;
    margin-top: 10px;
    margin-bottom: 0px;
    height: 50px;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-transition: 2s;
    transition: 2s;
}

.ln-content .title a{
    text-decoration: none; 
    color: #000;
    font-family: jost;
    letter-spacing: -0.025em;
    font-size: 16px;
}
.ln-content .title a:hover{
    color: #6c757d
}
.ln-content .excerpt{
    font-size: 15px;
    line-height: 1.5;
    margin-top: 1rem;
    font-family: 'Lato', sans-serif;
    text-align: justify;
    height: 90px;
    overflow: hidden;
}
.ln-content .readmore, .entry-details a{
    background: #f2f4f6;
    color: #000;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    padding: 7px;
    border-radius: 70px;
    text-decoration: none;
    -webkit-transition: cubic-bezier(0.6, 0.04, 0.98, 0.335);
    transition: cubic-bezier(0.6, 0.04, 0.98, 0.335);
    text-transform: uppercase;
    font-size: 11px;
    font-family: jost;
}
.ln-content .readmore:hover, .entry-details a:hover{
    color: #f2f4f6;
    background: #000;
}
.ln-content .readmore:after,  .entry-details a:after{
    display: inline-block;
    margin-left: 8px;
    font-family: FontAwesome;
    font-size: 8px;
    content: "\f054";
    margin-top: 2px;
}


.svgel{
    width: 60%;
    text-align: center;
    display: block;
    margin: 0 auto;
    overflow: hidden;
    padding: 40px;

}
.pathh{
    margin-bottom: 20px;
}
.goback{
    margin-top: 10px;
    background: #03a9f4;
    color: #fff;
    text-decoration: none;
    padding: 8px 25px;
    border-radius: 10px;
}
.path{
    color: #03a9f4;
    opacity: 0.5;
}

.st0{fill:#4A515B;}
.st1{font-family:'Montserrat-Bold';}
.st2{
    font-size:136.0123px;
    margin-left: 20px
}
.st6{font-size:136.0123px;}
.st3{letter-spacing:-3;}
.st4{fill:#03a9f4;}
.st5{fill:#03a9f4;}

.footer{
    padding: 30px;
    background: #47ccde;
    margin-top: auto;
    text-align: center;
}
.footer ul {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0;
}
.footer ul li{
    padding: 10px;
}
.footer ul li a{
    color: #fff;
    text-decoration: none;
}
.blogleft {
    float: left;
    width: 70%;
    margin-right: 5%;
    padding: 25px;
    -webkit-transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    
    background: #fff;
    position: relative;
}
.blogright {
    width: 25%;
    float: left;
}
.categories .blogleft, .latest .blogleft {
    margin-top: -60px;
}

.post-img img{
    width: 100%;
}
.wp-block-image img {
    width: 100%;
}
.blogleft .content p {
    font-size: 18px;
    margin-top: 15px;
}
.blogleft .content ul li {
    font-size: 22px;
}
.blogleft h1{
    font-size: 36px;
    font-family: 'Montserrat', sans-serif;
    line-height: 48px;
    margin-bottom: 20px;
    text-transform: capitalize;
    font-weight: 600;
}
.auth {
    font-size: 16px;
    color: #47ccde;
    font-weight: bold;
    margin-left: 5px;
    margin-bottom: 20px;
}
.content span{
    font-size: 16px;
    margin-bottom: 20px;
}
.sbimg img{
    width: 100%;
}
.sbpost{
    box-shadow: 0 2px 48px 0 rgba(0,0,0,.08);
    padding: 9px;
    margin: 10px;

}
.sbtitle a {
    font-weight: 100;
    text-decoration: none;
}
.blogright h3 {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    color: #47ccde;
}
span.authimg img {
    width: 50px;
    border-radius: 50px;
}
.blogright .sbbanner{
    margin-top: 50px;
}
.sbbanner img{
width: 100%;
    
}
.post .content img{
    text-align: center;
    display: block;
    margin: 0 auto;
}
.desksocial {
    display: flex;
    justify-content: center;
}
.desksocial div {
    outline: 0;
}
.categories .post-image1 img, .categories .post-image img{
    width: 100%;
}
.categories .post-image1{
    position: relative;
}
.categories .post-image{
    overflow: hidden;
    height: 200px;
}
.categories .post-image1{
    overflow: hidden;
    position: relative;
}
.categories .post-image img, .categories .post-image1 img {
    width: 100%;
    -webkit-filter: brightness(70%);
            filter: brightness(70%);
    -webkit-transition: -webkit-transform 2s, -webkit-filter 1.5s ease-in-out;
    transition: -webkit-transform 2s, -webkit-filter 1.5s ease-in-out;
    transition: transform 2s, filter 1.5s ease-in-out;
    transition: transform 2s, filter 1.5s ease-in-out, -webkit-transform 2s, -webkit-filter 1.5s ease-in-out;
    height:100%
}
.categories .post-image img:hover, .categories .post-image1 img:hover{
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    -webkit-filter: brightness(100%);
            filter: brightness(100%)
}

.categories .ln-box1 {
   
    box-shadow: 0 2px 48px 0 rgba(0,0,0,.08);
    padding: 20px;
    overflow: hidden;
}
.categories .ln-box2{
    overflow: hidden;
    height: 450px;
    box-shadow: 0 2px 48px 0 rgba(0,0,0,.08);
    padding: 9px;
    margin: 10px;
}

.categories .ln-box2 .post-image {
    height: 200px;
    overflow: hidden;
}
.categories .ln-content .excerpt{
    height: 70px;
}
.latest .post-image1 img, .latest .post-image img{
    width: 100%;
}
.latest .post-image1{
    position: relative;
}
.latest .post-image{
    overflow: hidden;
    height: 200px;
}
.latest .post-image1{
    overflow: hidden;
    position: relative;
}
.latest .post-image img, .latest .post-image1 img {
    width: 100%;
    -webkit-filter: brightness(70%);
            filter: brightness(70%);
    -webkit-transition: -webkit-transform 2s, -webkit-filter 1.5s ease-in-out;
    transition: -webkit-transform 2s, -webkit-filter 1.5s ease-in-out;
    transition: transform 2s, filter 1.5s ease-in-out;
    transition: transform 2s, filter 1.5s ease-in-out, -webkit-transform 2s, -webkit-filter 1.5s ease-in-out;
    height:100%
}
.latest .post-image img:hover, .latest .post-image1 img:hover{
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    -webkit-filter: brightness(100%);
            filter: brightness(100%)
}

.latest .ln-box1 {
    box-shadow: 0 2px 48px 0 rgba(0,0,0,.08);
    padding: 20px;
  
    overflow: hidden;
}
.latest .ln-box2{
    overflow: hidden;
    height: 450px;
    box-shadow: 0 2px 48px 0 rgba(0,0,0,.08);
    padding: 9px;
    margin: 10px;
}

.latest .ln-box2 .post-image {
    height: 200px;
    overflow: hidden;
}
.headerbg{
    height: 450px;
    margin-top: -20px;
    background-attachment: fixed;
    position: relative;
    width: 100%;
    overflow: hidden;
    background: #47ccde;
}
.headerbg video{
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: 100%;
    z-index: 0;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);

}
.headerbg h2{
    position: absolute;
    left: 50%;
    font-size: 40px;
    color: #fff;
    z-index: 999;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-transform: capitalize;
}
.pagination {
    margin: 15px auto;
    display: table;
}
.pagination > .active > a{
    background-color: #47ccde !important;
    border-color: #47ccde !important;
}
.pagination > li > a{
    border: 1px solid #47ccde !important;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: #47ccde !important;
    border-color: #47ccde !important;
    outline: none !important
}
.pagination > li > a, .pagination > li > span{
    color: #47ccde
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-radius: unset !important
}
.latest .ln-content .excerpt{
    height: 70px;
}
.searchbar {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    position: relative;
    margin-top: 20px;
}
.searchbar form.searchform{
    width: 100%;
}
.searchbar input {
    width: 100%;
    border: 4px double black;
}
.searchbar input::-webkit-input-placeholder{
    margin-left: 20px
}
.searchbar input::-moz-placeholder{
    margin-left: 20px
}
.searchbar input:-ms-input-placeholder{
    margin-left: 20px
}
.searchbar input::-ms-input-placeholder{
    margin-left: 20px
}
.searchbar input::placeholder{
    margin-left: 20px
}
.searchbar .sricon {
    position: absolute;
    font-size: 16px;
    top: 15%;
    right: 8px;
}
@font-face {
  font-family: jost;
  src: url(/static/media/jost-regular.9d6614a3.woff);
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*Responsive*/



@media (max-width: 767px) {
    .headerbg {
        height: 200px;
        margin-top: unset;
    }
    .blogright{
        display: none;
    }
    .blogleft{
        width: 90%;
    }
    .writerbox {
        height: auto !important;
        margin-bottom: 25px;
    }
    .categories .ln-box2{
        margin: 10px 0;
    }
}
.mfooter {
    background: #47ccde;
    height: 40px;
    width: 100%;
    position: fixed;
    bottom: 0;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    z-index: 9999999;
    color: #fff
}
.mfooter a{
    color: #fff;
    text-decoration: none
}
.ln{
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
    align-items: flex-start;
    position: relative;
    box-shadow: 0 2px 48px 0 rgba(0,0,0,.08);
    margin-bottom: 15px;
}
.ln2{
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
    flex-direction: row-reverse;
    align-items: flex-start;
    position: relative;
    box-shadow: 0 2px 48px 0 rgba(0,0,0,.08);
    margin-bottom: 15px;
}
.lnimage {
    width: 100%;
    height: auto;
    flex: 2 1;
    position: relative;

}
.ln2 .lnimage img{
    float: right;
}
.lnimage img{
    width: 90%;
    height: 120px;
    object-fit: cover;
    -webkit-filter: brightness(0.8);
            filter: brightness(0.8);
}
.ln-right{
    flex: 2 1;
}
.lnimage a.category {
    position: absolute;
    left: 5px;
    color: #fff;
    font-size: 8px;
    bottom: 0;
}
.ln-right h2.title {
    font-size: 14px;
    margin: 0;
}
.ln-right h2.title a{
   
    text-decoration: none;
    color: #000;
    font-family: jost;
    letter-spacing: -0.025em;
}
.ln .ln-right h2.title, .ln .ed, .ln .date {
    text-align: end
}
.ln2 .ln-right h2.title {
    text-align: start
    
}
.ln2 .ln-right h2.title a {
    text-decoration: none;
    color: #000;
    font-family: jost;
    letter-spacing: -0.025em;
    
}
.ln2 .lnimage a.category{
    right: 5px;
    left: unset;
}
p.date {
    font-size: 8px;
    margin-bottom: 5px;
}
.ed a {
    text-decoration: none;
    font-size: 6px;
    background: #47ccde;
    padding: 5px 15px;
    color: #fff;
}
.mobile .ln .ed{
    position: absolute;
   right: 10px;
    bottom: 10px;
    
}
.mobile .ln2 .ed{
    position: absolute;
    left: 10px;
    bottom: 10px;
    
}
.mobile h3{
    text-transform: uppercase;
}
.mobile hr {
    border: 0;
    border-top: 2px solid #7d7d7d;
}

.navbar-default .navbar-toggle, .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    border: unset;
    background: transparent;
}
.navbar-default .navbar-toggle .icon-bar {
    background-color: #000;
}
.mobile .navbar-toggle{
    float: left;
}
.mobile .navbar-default .navbar-brand{
    display: none;
}
.mobile h3{
    margin: unset;
}

.sidebar{
    background: #47ccde;
    width: 50%;
    z-index: 9999 !important;
}
.mobile .header {
    height: 50px;
}
.hamburger
  {
    margin-top: 15px;
    margin-left: 18px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.hamburger span
{  background: #47ccde;
  border-radius: 3px;
  display: block;
  margin-bottom: 3px;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease;
  height: 2px;
  width: 25px
}
.hamburger span:nth-child(1)
  {width: 20px;
display: none;
}
.hamburger span:nth-child(2)
  {width: 15px}
.hamburger:hover span:nth-child(3)
{  width: 20px}
.hamburger:hover span:nth-child(2)
{  width: 25px}
.hamburger:hover span:nth-child(1)
{  width: 215px}

.mpost .post-img {
    position: absolute;
    top: 0;
    z-index: -1;
}
.mpost .content {
    background: white;
    padding: 10px 20px 10px 25px;
    text-align: justify;
    margin-top: 35%;
    margin-left: 8%;
    margin-bottom: 40px;
}
.mheader {
    height: 40px;
}
.mpost .content h1 {
    font-size: 18px;
    text-align: left;
    text-transform: uppercase;
    font-weight: bold;
}
.mpost .content h1:before {
    content: "";
    position: absolute;
    background: red;
    height: 45px;
    width: 3px;
    left: 12%;
}
.mobile .pagination > li > a {
    font-size: 10px;
    padding: 1px 5px;
}
/* .social{
    display: flex;
    justify-content: center;
    padding: 5px;
    margin-bottom: 50px;
} */
.social {

   
    position: fixed;
    top:50%;
    left: 0;
}
.social div{
    margin-left: 5px;
    outline: none;
}
.mpost .post-img img{
    height: 200px;
}
.mobile .pagination {
    margin-bottom: 50px;
}
.dockicon {
    display: flex;
    justify-content: space-around;
    vertical-align: middle;
    align-items: center;
    align-content: center;
    height: 40px;
}


.mlogo {
    width: 100px;
    margin: 0 auto;
    margin-top: 12px;
}
.mlogo img{
    width: 100%;
}

.mmenu{
    list-style: none;
    color: #fff;
    padding: 15px;
}
.mmenu a{
    color: #fff;
    text-decoration: none
}
.mmenu li {
    padding: 8px;
}
.mmenu .item236:before{
    font-family: "Font Awesome 5 Free";
   content: "\f015";
   display: inline-block;
   padding-right: 5px;
   vertical-align: middle;
   font-weight: 900;
   margin-top: -2px;
}
.mmenu .item237:before{
    font-family: "Font Awesome 5 Free";
   content: "\f001";
   display: inline-block;
   padding-right: 5px;
   vertical-align: middle;
   font-weight: 900;
   margin-top: -2px;
}
.mmenu .item238:before{
    font-family: "Font Awesome 5 Free";
   content: "\f008";
   display: inline-block;
   padding-right: 5px;
   vertical-align: middle;
   font-weight: 900;
   margin-top: -2px;
}
.mmenu .item239:before{
    font-family: "Font Awesome 5 Free";
   content: "\f2e7";
   display: inline-block;
   padding-right: 5px;
   vertical-align: middle;
   font-weight: 900;
   margin-top: -2px;
}
.mmenu .item240:before{
    font-family: "Font Awesome 5 Free";
   content: "\f182";
   display: inline-block;
   padding-right: 5px;
   vertical-align: middle;
   font-weight: 900;
   margin-top: -2px;
}
.mmenu .item241:before{
    font-family: "Font Awesome 5 Free";
   content: "\f5bd";
   display: inline-block;
   padding-right: 5px;
   vertical-align: middle;
   font-weight: 900;
   margin-top: -2px;
}
.mmenu .item243:before{
    font-family: "Font Awesome 5 Free";
   content: "\f03d";
   display: inline-block;
   padding-right: 5px;
   vertical-align: middle;
   font-weight: 900;
   margin-top: -2px;
}
.mmenu .item267:before{
    font-family: "Font Awesome 5 Free";
   content: "\f304";
   display: inline-block;
   padding-right: 5px;
   vertical-align: middle;
   font-weight: 900;
   margin-top: -2px;
}
.mmenu .item287:before{
    font-family: "Font Awesome 5 Free";
   content: "\f6e2";
   display: inline-block;
   padding-right: 5px;
   vertical-align: middle;
   font-weight: 900;
   margin-top: -2px;
}
.mmenu .item288:before{
    font-family: "Font Awesome 5 Free";
   content: "\f2bb";
   display: inline-block;
   padding-right: 5px;
   vertical-align: middle;
   font-weight: 900;
   margin-top: -2px;
}
.mmenu .item301:before{
    font-family: "Font Awesome 5 Free";
   content: "\f557";
   display: inline-block;
   padding-right: 5px;
   vertical-align: middle;
   font-weight: 900;
   margin-top: -2px;
}
.mauthimg img{
    width: 30px;
    border-radius: 50px;
}
.mpcnt img{
    width: 100%;
    object-fit: contain;
    object-position: center;
    padding: 0;
    margin: 5px 0;
    height: auto;
}

.video div{
    box-shadow: 0 2px 48px 0 rgba(0,0,0,.08);
    padding: 5px;
    margin: 5px;
}
.videos {
    margin-bottom: 50px;
}
p.vtime {
    font-size: 12px;
}

.writerimg{
    width: 130px;
    height: 110px;
    overflow: hidden;
}
.writerimg img{
    width: 100%;
}
.writerbox{
    box-shadow: 0 2px 48px 0 rgba(0,0,0,.08);
    padding: 20px;
    display: flex;
    justify-content: space-between;
    height: 300px;
    margin: 20px;
}
.wcontent {
    width: 70%;
    margin-left: 20px;
    text-align: justify;
}
.wcontent h3{
    margin-top: unset;
}
.wsocial {
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    color: #47ccde;
}
.wsocial a{
    color: #47ccde;
}
.wincont{
    display: block;
    width: 100%;
    text-align: justify;
    box-shadow: 0 2px 48px 0 rgba(0,0,0,.08);
    padding: 40px;
    margin: 100px auto;
    line-height: 25px;
}
.aboutcont{
    display: block;
    width: 100%;
    text-align: justify;
    box-shadow: 0 2px 48px 0 rgba(0,0,0,.08);
    padding: 40px;
    margin: 100px auto;
    line-height: 25px;
}
.oneline{
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
}

.oneline input[type="text"], .oneline input[type="email"], .oneline input[type="tel"] {
    width: 75%;
    height: 30px;
    border: none;
    border: 1px solid #47ccde;
    outline: none;
}
.oneline p {
    margin: unset;
    padding: 5px;
    font-size: 12px;
    font-weight: bold;
}
.cntform{
    width: 80%;
    box-shadow: 0 2px 48px 0 rgba(0,0,0,.08);
    padding: 20px;
    margin: 0 auto;
}
.cntform button{
    border: none;
    background: #47ccde;
    color: #fff;
    padding: 5px 20px;
    margin: 0 auto;
    display: block;
}
.message  textarea{
    width: 88%;
    height: 100px;
    border: none;
    border: 1px solid #47ccde;
    outline: none;
}


